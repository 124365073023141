<template>
 <div v-if="groupData.length > 0">
  <h3 class="text-lg font-medium leading-6 text-gray-900">
   {{ $t("groupData") }}
  </h3>
  <dl :class="`mb-5 grid grid-cols-1 gap-1 sm:grid-cols-${groupData.length}`">
   <div
    v-for="item in groupData"
    :key="item.name"
    class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-4"
   >
    <dt class="truncate text-sm font-medium text-gray-500">{{ $t(item.name) }}</dt>
    <dd class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
     {{ item.name.includes("_") ? item.stat + "(s)" : item.stat }}
    </dd>
   </div>
  </dl>
 </div>
</template>

<script>
export default {
 props: ["data"],
 computed: {
  groupData() {
   let obj = {};
   let arr = [];
   if (this.data && this.data.length > 0) {
    let keys = Object.keys(this.data[0]);
    keys.forEach((key) => {
     if (key !== "extension" && key !== "agent" && !key.includes("avg")) {
      obj[key] = 0;
     }
    });
    this.data.forEach((el) => {
     for (const objKey in obj) {
      if (Object.hasOwnProperty.call(obj, objKey)) {
       obj[objKey] = obj[objKey] + el[objKey];
      }
     }
    });
   }
   for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
     let objArr = {};
     objArr["name"] = key;
     objArr["stat"] = key.includes("duration") ? (obj[key] / obj["calls"]).toFixed(0) : obj[key];
     if (!key.includes("_") || (key.includes("_") && key.includes("duration"))) {
      arr.push(objArr);
     }
    }
   }
   return arr;
  },
 },
};
</script>

<style></style>
